import axios from 'axios'

export const baseURL = `https://adonisapi.qodeex.com/api`

export const imgURL = `https://adonisapi.qodeex.com/uploads`

const instance = axios.create({
    baseURL: baseURL,
    // timeout: 1000,
    headers : {
      'Authorization' : `Bearer ${localStorage.getItem('token')}`
    }
  });

export default instance