import React from 'react'
import EditSliderComponent from '../../components/dashboard/EditSlider'

const EditSlider = () => {
    return (
        <EditSliderComponent />
    )
}

export default EditSlider
