import React, { useContext } from 'react'
import { Switch } from "react-router-dom"
import { RouteWithSubRoutes } from '../../routes/router'
import { Link } from 'react-router-dom'
import { APIContext } from '../../context/Context'

const Dashboard = ({ routes }) => {

    const { user } = useContext(APIContext)

    return (
        <div className="page-container">
               <div className="page-sidebar">
                <Link className="logo-box" href="index.html">
                    <span>Qodeex</span>
                    <i className="icon-radio_button_unchecked" id="fixed-sidebar-toggle-button"></i>
                    <i className="icon-close" id="sidebar-toggle-button-close"></i>
                </Link>
                <div className="page-sidebar-inner">
                    <div className="page-sidebar-menu">
                        <ul className="accordion-menu">


                            <li className="active-page">
                                <Link to="/dashboard">
                                    <i className="menu-icon icon-home4"></i><span>Dashboard</span>
                                </Link>
                            </li>


                            {/* <li>
                                <Link to="/emails">
                                    <i className="menu-icon icon-inbox"></i><span>Email</span>
                                </Link>
                            </li> */}


                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-layers"></i><span>Sliders</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/sliders"><i className="menu-icon icon-layers"></i> Show sliders</Link></li>
                                    {/* <li><Link to="/add-slider"><i className="menu-icon icon-layers"></i> Add new slider</Link></li> */}
                                </ul>
                            </li>


                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-cog"></i><span>Services</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/services"><i className="menu-icon icon-cog"></i> Show services</Link></li>
                                    {/* <li><Link to="/add-service"><i className="menu-icon icon-cog"></i> Add new service</Link></li> */}
                                </ul>
                            </li>


                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-dropbox"></i><span>Products</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/products"><i className="menu-icon icon-dropbox"></i> Show products</Link></li>
                                    {/* <li><Link to="/add-product"><i className="menu-icon icon-dropbox"></i> Add new product</Link></li> */}
                                </ul>
                            </li>


                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-layers"></i><span>Posts</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/posts">Show posts</Link></li>
                                    {/* <li><Link to="/add-post">Add new post</Link></li> */}
                                </ul>
                            </li>


                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-info"></i><span>About Us</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/about-us"><i className="menu-icon icon-info"></i> Show About Us</Link></li>
                                    {/* <li><Link to="/add-about-us"><i className="menu-icon icon-info"></i> Add new about-us</Link></li> */}
                                </ul>
                            </li>

                            
                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-users"></i><span>Team</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/team"><i className="menu-icon icon-users"></i> Show Team</Link></li>
                                    {/* <li><Link to="/add-member"><i className="menu-icon icon-person"></i> Add new member</Link></li> */}
                                </ul>
                            </li>


                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-comment"></i><span>Testimonials</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/testimonials"><i className="menu-icon icon-comment"></i> Show Testimonials</Link></li>
                                    {/* <li><Link to="/add-testimonial"><i className="menu-icon icon-comment"></i> Add new testimonial</Link></li> */}
                                </ul>
                            </li>


                            <li>
                                <Link to="javascript:void(0)">
                                    <i className="menu-icon icon-info"></i><span>Info</span><i className="accordion-icon fa fa-angle-left"></i>
                                </Link>
                                <ul className="sub-menu">
                                    <li><Link to="/info"><i className="menu-icon icon-info"></i> Edit Info</Link></li>
                                    {/* <li><Link to="/add-info"><i className="menu-icon icon-info"></i> Add new info</Link></li> */}
                                </ul>
                            </li>
                           
                           
                            {/* <li className="menu-divider"></li>
                            <li>
                                <Link to="index.html">
                                    <i className="menu-icon icon-help_outline"></i><span>Documentation</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="index.html">
                                    <i className="menu-icon icon-public"></i><span>Changelog</span><span className="label label-danger">1.0</span>
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>



            <div className="page-content" style={{ minHeight : '100vh' , backgroundColor : '#F7F9FA' }}>
            <div className="page-header">
                    <nav className="navbar navbar-default">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <div className="logo-sm">
                                    <a href="javascript:void(0)" id="sidebar-toggle-button"><i className="fa fa-bars"></i></a>
                                </div>
                            </div>
                        
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav">
                                    <li><a href="javascript:void(0)" id="collapsed-sidebar-toggle-button"><i className="fa fa-bars"></i></a></li>
                                    <li><a href="javascript:void(0)" id="toggle-fullscreen"><i className="fa fa-expand"></i></a></li>
                                </ul>
                                <ul className="nav navbar-nav navbar-right">
                                   <li className="dropdown user-dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><img src="https://icon-library.com/images/person-image-icon/person-image-icon-2.jpg" alt="" className="img-circle" /></a>
                                        <ul className="dropdown-menu" style={{ width : 250 }}>
                                        <li><h5 style={{ fontWeight : 'bold' , marginLeft : 15 , marginRight : 5 }}>Hello {user.username}</h5></li>
                                            {/* <li><Link to="/emails"><span className="badge pull-right badge-danger">42</span>Emails</Link></li> */}
                                            <li role="separator" className="divider"></li>
                                            <li><Link to="/editprofile">Account Settings</Link></li>
                                            <li><a href="#" onClick={() => {
                                                localStorage.removeItem("token")
                                                window.location.href = "/login"
                                            }}>Log Out</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                    <Switch>
                {routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                ))}
                </Switch>
            </div>
        </div>
    )
}

export default Dashboard
