import React from 'react'
import Logo from '../logo/logo.png'
const Home = () => {
    return (
        <div className="page-inner">
        <div className="page-title">
            <h3 className="breadcrumb-header">Dashboard</h3>
        </div>
        <div id="main-wrapper">
        <div className="row">
                            {/* <div className="col-lg-3 col-md-6">
                                <div className="panel panel-white stats-widget">
                                    <div className="panel-body">
                                        <div className="pull-left">
                                            <span className="stats-number">$781,876</span>
                                            <p className="stats-info">Total Income</p>
                                        </div>
                                        <div className="pull-right">
                                            <i className="icon-arrow_upward stats-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="panel panel-white stats-widget">
                                    <div className="panel-body">
                                        <div className="pull-left">
                                            <span className="stats-number">578,100</span>
                                            <p className="stats-info">Downloads</p>
                                        </div>
                                        <div className="pull-right">
                                            <i className="icon-arrow_downward stats-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="panel panel-white stats-widget">
                                    <div className="panel-body">
                                        <div className="pull-left">
                                            <span className="stats-number">+23,356</span>
                                            <p className="stats-info">New Registrations</p>
                                        </div>
                                        <div className="pull-right">
                                            <i className="icon-arrow_upward stats-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="panel panel-white stats-widget">
                                    <div className="panel-body">
                                        <div className="pull-left">
                                            <span className="stats-number">58%</span>
                                            <p className="stats-info">Finished Tasks</p>
                                        </div>
                                        <div className="pull-right">
                                            <i className="icon-arrow_upward stats-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <img src={Logo} style={{ width : '100%' , height : '600px' , objectFit : 'contain' }} />
                        </div>
        </div>
        </div>
    )
}

export default Home
