import React from 'react'
import EditAboutUsComponent from '../../components/dashboard/EditAboutUs'

const EditAboutUs = () => {
    return (
        <EditAboutUsComponent />
    )
}

export default EditAboutUs
