import React, { createContext, useEffect , useState } from 'react'
import apis from '../apis/http'
import instance from '../apis/http'


export const APIContext = createContext()

const Context = ({ children }) => {

    const [authenticated , setAuthenticated] = useState(false)
    const [user , setUser] = useState({})
    const [sliders , setSliders] = useState([])
    const [services , setServices] = useState([])
    const [products , setProducts] = useState([])
    const [posts , setPosts] = useState([])
    const [aboutUs , setAboutUs] = useState([])
    const [team , setTeam] = useState([])
    const [testimonials , setTestimonials] = useState([])
    const [info , setInfo] = useState({})

    useEffect(() => {

        const checkAuthentication = async () => {
            await instance.checkAuth().then((res) => {
                setAuthenticated(true)
                setUser(res.data.user)
            })
            .catch(err => {
                if(window.location.pathname !== '/login')
                window.location.href = "/login"
            })
        }

        const fetchSliders = async () => {
            await apis.fetchSliders().then(res => setSliders(res.data)).catch(err => {})
        }

        const fetchServices = async () => {
            await apis.fetchServices().then(res => setServices(res.data)).catch(err => {})
        }

        const fetchProducts = async () => {
            await apis.fetchProducts().then(res => setProducts(res.data)).catch(err => {})
        }

        const fetchPosts = async () => {
            await apis.fetchPosts().then(res => setPosts(res.data)).catch(err => {})
        }

        const fetchAboutUs = async () => {
            await apis.fetchAboutUs().then(res => setAboutUs(res.data)).catch(err => {})
        }

        const fetchTeam = async () => {
            await apis.fetchTeam().then(res => setTeam(res.data)).catch(err => {})
        }

        const fetchTestimonials = async () => {
            await apis.fetchTestimonials().then(res => setTestimonials(res.data)).catch(err => {})
        }

        const fetchInfo = async () => {
            await apis.fetchInfo().then(res => setInfo(res.data)).catch(err => {})
        }

        checkAuthentication()

        fetchSliders()

        fetchServices()

        fetchProducts()

        fetchPosts()

        fetchAboutUs()

        fetchTeam()

        fetchTestimonials()

        fetchInfo()

    } , [])

    return (
        <APIContext.Provider value={{ 
            authenticated,
            setAuthenticated,
            user,
            setUser,
            sliders,
            setSliders,
            services,
            setServices,
            products,
            setProducts,
            posts,
            setPosts,
            aboutUs,
            setAboutUs,
            team,
            setTeam,
            testimonials,
            setTestimonials,
            info,
            setInfo
             }}>
            { children }
        </APIContext.Provider>
    )
}

export default Context
