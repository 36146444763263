import { Redirect, Route } from "react-router-dom";
import Login from "../pages/dashboard/Login";
import React from 'react'
import Dashboard from "../pages/dashboard/Dashboard";
import Home from "../pages/dashboard/Home";
import EditProfile from "../pages/dashboard/EditProfile";
import Email from "../pages/dashboard/Email";
import Sliders from "../pages/dashboard/Sliders";
import EditSlider from "../pages/dashboard/EditSlider";
import Services from "../pages/dashboard/Services";
import EditService from "../pages/dashboard/EditService";
import Products from "../pages/dashboard/Products";
import EditProduct from "../pages/dashboard/EditProduct";
import Posts from "../pages/dashboard/Posts";
import EditPost from "../pages/dashboard/EditPost";
import EditAboutUs from "../pages/dashboard/EditAboutUs";
import AboutUs from "../pages/dashboard/AboutUs";
import EditTeam from "../pages/dashboard/EditTeam";
import Teams from "../pages/dashboard/Team";
import Testimonials from "../pages/dashboard/Testimonials";
import EditTestimonial from "../pages/dashboard/EditTestimonial";
import EditInfo from "../pages/dashboard/EditInfo";

  export function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }

  const routes = [
    {
      path: "/login",
      component: Login
    },
    {
      path: "/",
      component: Dashboard,
      routes: [
        {
          path: "/dashboard",
          component: Home
        },
        {
          path: "/editprofile",
          component: EditProfile
        },
        // Sliders
        {
          path: "/sliders",
          component: Sliders
        },
        {
          path: "/slider/:id",
          component: EditSlider
        },
        // Services
        {
          path: "/services",
          component: Services
        },
        {
          path: "/service/:id",
          component: EditService
        },
        // Products
        {
          path: "/products",
          component: Products
        },
        {
          path: "/product/:id",
          component: EditProduct
        },
        // Posts
        {
          path: "/posts",
          component: Posts
        },
        {
          path: "/post/:id",
          component: EditPost
        },
        // About Us
        {
          path: "/about-us",
          component: AboutUs,
          exact: true
        },
        {
          path: "/about-us/:id",
          component: EditAboutUs
        },
        // Team
        {
          path: "/team",
          component: Teams,
          exact: true
        },
        {
          path: "/team/:id",
          component: EditTeam
        },
        // Testimonials
        {
          path: "/testimonials",
          component: Testimonials
        },
        {
          path: "/testimonial/:id",
          component: EditTestimonial
        },
        // Info
        {
          path: "/info",
          component: EditInfo,
          exact: true
        },
        {
          path: "*",
          component: () => <Redirect to="/dashboard" />
        }
        // {
        //   path: "/info/:id",
        //   component: EditInfo
        // }
        // {
        //   path: "/emails",
        //   component: Email
        // },
      ]
    }
  ];

  export default routes