import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import apis from '../../apis/http'

const EditTestimonial = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };


    const { id } = useParams()

    const [testimonial , setTestimonial] = useState({})

    useEffect(() => {
        const fetchOneTestimonial = async () => {
            await apis.fetchTestimonial(id).then(res => {
                setTestimonial({
                ...res.data,
                image : '',
                client_name : JSON.parse(res.data.client_name),
                position : JSON.parse(res.data.position),
                specialization : JSON.parse(res.data.specialization),
                message : JSON.parse(res.data.message)
            })
            }).catch(err => {
                window.location.href = "/testimonials"
            })
        }

        fetchOneTestimonial()
    } , [])

    const handleUpdate = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('client_name' , JSON.stringify(testimonial.client_name))
        formData.append('position' , JSON.stringify(testimonial.position))
        formData.append('specialization' , JSON.stringify(testimonial.specialization))
        formData.append('message' , JSON.stringify(testimonial.message))
        formData.append('rate' , testimonial.rate)
        await apis.editTestimonial(id , formData).then(res => {
            alertMessage('success' , res.data.message)
            setTimeout(() => {
                window.location.reload()
            } , 2000)
        })
        .catch(err => {
            if(err.response.status === 422)
            alertMessage('error' , err.response.data.errors[0].message)
            else
            alertMessage('error' , err.response.data.message)

            console.log(err.response.data)
        })
    }

    return (
        Object.keys(testimonial).length !== 0 ?
        <div className="page-inner">
        <div className="page-title">
            <h3 className="breadcrumb-header">Edit Testimonial</h3>
        </div>
        <div id="main-wrapper">
        <div className="row">
        <div className="col-md-12">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix">
                                    <h4 className="panel-title">Edit Testimonial</h4>
                                </div>
                                <div className="panel-body">
                                    <form method="post" onSubmit={(e) => handleUpdate(e)}>
                                    <div className="form-group">
    <label>English Client Name</label>
    <input type="text" defaultValue={testimonial.client_name.en} onChange={(e) => {
        setTestimonial({...testimonial , client_name : {...testimonial.client_name , en : e.target.value}})
    }} className="form-control" placeholder="Client Name" />
  </div>
  <div className="form-group">
    <label>Arabic Client Name</label>
    <input type="text" defaultValue={testimonial.client_name.ar} onChange={(e) => {
        setTestimonial({...testimonial , client_name : {...testimonial.client_name , ar : e.target.value}})
    }} className="form-control" placeholder="Client Name" />
  </div>
  <div className="form-group">
    <label>English Position</label>
    <input type="text" defaultValue={testimonial.position.en} onChange={(e) => {
        setTestimonial({...testimonial , position : {...testimonial.position , en : e.target.value}})
    }} className="form-control" placeholder="Position" />
  </div>
  <div className="form-group">
    <label>Arabic Position</label>
    <input type="text" defaultValue={testimonial.position.ar} onChange={(e) => {
        setTestimonial({...testimonial , position : {...testimonial.position , ar : e.target.value}})
    }} className="form-control" placeholder="Position" />
  </div>
  <div className="form-group">
    <label>Rate</label>
    <input type="number" defaultValue={testimonial.rate} onChange={(e) => {
        setTestimonial({...testimonial , rate : e.target.value})
    }} className="form-control" placeholder="Rate" />
  </div>
  <div className="form-group">
    <label>English Specialization</label>
    <input type="text" defaultValue={testimonial.specialization.en} onChange={(e) => {
        setTestimonial({...testimonial , specialization : {...testimonial.specialization , en : e.target.value}})
    }} className="form-control" placeholder="Specialization" />
  </div>
  <div className="form-group">
    <label>Arabic Specialization</label>
    <input type="text" defaultValue={testimonial.specialization.ar} onChange={(e) => {
        setTestimonial({...testimonial , specialization : {...testimonial.specialization , ar : e.target.value}})
    }} className="form-control" placeholder="Specialization" />
  </div>
  <div className="form-group">
    <label>English Message</label>
    <input type="text" defaultValue={testimonial.message.en} onChange={(e) => {
        setTestimonial({...testimonial , message : {...testimonial.message , en : e.target.value}})
    }} className="form-control" placeholder="Message" />
  </div>
  <div className="form-group">
    <label>Arabic Message</label>
    <input type="text" defaultValue={testimonial.message.ar} onChange={(e) => {
        setTestimonial({...testimonial , message : {...testimonial.message , ar : e.target.value}})
    }} className="form-control" placeholder="Message" />
  </div>
  <button type="submit" className="btn btn-primary">Submit</button>
</form>
                                </div>
                            </div>
                        </div>
                        </div>
        </div>
        </div>
        :
        <></>
    )
}

export default EditTestimonial
