import React, { useContext, useState } from 'react'
import { APIContext } from '../../context/Context'
import dateFormat from 'dateformat'
import apis from '../../apis/http'
import { useSnackbar } from 'notistack'
import { DataGrid } from '@material-ui/data-grid';


const Testimonials = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const { testimonials , setTestimonials } = useContext(APIContext)

    const [clientName , setClientName] = useState({ en : "" , ar : ""})

    const [position , setPosition] = useState({ en : "" , ar : ""})

    const [specialization , setSpecialization] = useState({ en : "" , ar : ""})

    const [message , setMessage] = useState({ en : "" , ar : ""})

    const [rate , setRate] = useState(5)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        clientName.en && clientName.ar ? formData.append('client_name' , JSON.stringify(clientName)) : <></>
        position.en && position.ar ? formData.append('position' , JSON.stringify(position)) : <></>
        specialization.en && specialization.ar ? formData.append('specialization' , JSON.stringify(specialization)) : <></>
        message.en && message.ar ? formData.append('message' , JSON.stringify(message)) : <></>
        formData.append('rate' , rate)
        await apis.addTestimonial(formData).then(res => {
            setTestimonials(testimonials => [...testimonials , {
                id : res.data.data.id,
                client_name : res.data.data.client_name,
                position : res.data.data.position,
                rate,
                specialization : res.data.data.specialization,
                message : res.data.data.message,
                created_at : res.data.data.created_at
            }])
            setClientName({en : "" , ar : ""})
            setPosition({en : "" , ar : ""})
            setRate(5)
            setSpecialization({en : "" , ar : ""})
            setMessage({en : "" , ar : ""})
            alertMessage('success' , res.data.message)
        }).catch(err => {
            alertMessage('error' , `${err.response.data.errors[0].field} ${err.response.data.errors[0].message}`)
        })
    }

    const handleDelete = async (id) => {
        await apis.deleteTestimonial(id).then(() => {
            setTestimonials(testimonials => testimonials.filter(testimonial => testimonial.id !== id))
        })
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'client_name', headerName: 'English Client Name', width: 200,
        renderCell: (params) => JSON.parse(params.value).en
        },
        { field: 'position', headerName: 'English Position', width: 200,
        renderCell: (params) => JSON.parse(params.value).en
        },
        { field: 'rate', headerName: 'Rate', width: 100 },
        { field: 'specialization', headerName: 'English Specialization', width: 200,
        renderCell: (params) => JSON.parse(params.value).en
        },
        { field: 'created_at', headerName: 'Created At', width: 250,
        valueGetter: (params) => dateFormat(params.created_at , 'dddd, mmmm dS, yyyy, h:MM:ss TT')
        },
        {
        field: 'edit',
        headerName: 'Edit',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-edit" onClick={() => window.location.href = `/testimonial/${params.id}`} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'green' }}></i>)
        },
        {
        field: 'delete',
        headerName: 'Delete',
        width: 160,
        renderCell: (params) =>
        (<i className="fa fa-trash" onClick={() => handleDelete(params.id)} style={{ cursor : 'pointer' , fontWeight : 'bold' , fontSize : 30 , color : 'red' }}></i>)
        },
        
      ];

    return (
        <div className="row" style={{ marginLeft: 10 , marginRight : 10 }}>
        <div className="col-md-12">
        <div className="panel panel-white">
        <div className="panel-heading">
            <h4 className="panel-title">Testimonials</h4>
        </div>
        <div className="panel-body">
            <button type="button" className="btn btn-success m-b-sm" data-toggle="modal" data-target="#myModal">Add testimonial</button>
            <form id="add-row-form" action="javascript:void(0);" onSubmit={(e) => handleSubmit(e)}>
            <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel">Add testimonial</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <input type="text" className="form-control" value={clientName.en} onChange={(e) => setClientName({...clientName , en : e.target.value})} placeholder="English Client Name" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={clientName.ar} onChange={(e) => setClientName({...clientName , ar : e.target.value})} placeholder="Arabic Client Name" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={position.en} onChange={(e) => setPosition({...position , en : e.target.value})} placeholder="English Position" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={position.ar} onChange={(e) => setPosition({...position , ar : e.target.value})} placeholder="Arabic Position" />
                            </div>
                            <div className="form-group">
                                <input type="number" className="form-control" value={rate} onChange={(e) => setRate(e.target.value)} placeholder="Rate" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={specialization.en} onChange={(e) => setSpecialization({...specialization , en : e.target.value})} placeholder="English Specialization" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={specialization.ar} onChange={(e) => setSpecialization({...specialization , ar : e.target.value})} placeholder="Arabic Specialization" />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" value={message.en} onChange={(e) => setMessage({...message , en : e.target.value})} placeholder="English Message" />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" value={message.ar} onChange={(e) => setMessage({...message , ar : e.target.value})} placeholder="Arabic Message" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                            <button type="submit" onSubmit={(e) => handleSubmit(e)} className="btn btn-success">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
            <div style={{ height: 600, width: '100%' }}>
            <DataGrid rows={testimonials} columns={columns} pageSize={10} />
        </div>
            </div>
    </div>
    </div>
    </div>
    )
}

export default Testimonials
