import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import apis from '../../apis/http'
import { APIContext } from '../../context/Context'

const EditProfile = () => {

    const { enqueueSnackbar } = useSnackbar()

    const alertMessage = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const [show , setShow] = useState(false)

    const { user , setUser } = useContext(APIContext)

    const [username , setUsername] = useState('')

    const [password , setPassword] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('username' , username)
        formData.append('password' , password)
        await apis.editProfile(formData).then(res => {
            setUser({...user , username : username!== '' ? username : user.username})
            alertMessage('success' , res.data.message)
        })
        .catch(err => {
            if(err.response.status === 422)
            alertMessage('error' , err.response.data.errors[0].message)
            else
            alertMessage('error' , err.response.data.message)
        })
    }

    return (
        <div className="page-inner">
        <div className="page-title">
            <h3 className="breadcrumb-header">Edit Profile</h3>
        </div>
        <div id="main-wrapper">
        <div className="row">
        <div className="col-md-12">
                            <div className="panel panel-white">
                                <div className="panel-heading clearfix">
                                    <h4 className="panel-title">Edit Profile</h4>
                                </div>
                                <div className="panel-body">
                                    <form method="post" onSubmit={(e) => handleSubmit(e)}>
  <div className="form-group">
    <label>Email address</label>
    <input type="email" value={user.email} disabled className="form-control" placeholder="Email" />
  </div>
  <div className="form-group">
    <label>Username</label>
    <input type="text" defaultValue={user.username} onChange={(e) => {
        setUsername(e.target.value)
    }} className="form-control" placeholder="Username" />
  </div>
  <div className="form-group">
    <label>Password</label>
    <input type={show ? `text` : `password`} defaultValue={password} onChange={(e) => {
        setPassword(e.target.value)
    }} className="form-control" placeholder="Password" />
    <br></br>
    <button type="button" className="btn btn-secondary" onClick={() => setShow(!show)}>{ !show ? (<span>Show <i className="fa fa-eye"></i></span>) : (<span>Hide <i className="fa fa-eye-slash"></i></span>)}</button>
  </div>
  <button type="submit" className="btn btn-primary">Submit</button>
</form>
                                </div>
                            </div>
                        </div>
                        </div>
        </div>
        </div>
    )
}

export default EditProfile
