import React from 'react'
import EditProductComponent from '../../components/dashboard/EditProduct'

const EditProduct = () => {
    return (
        <EditProductComponent />
    )
}

export default EditProduct
