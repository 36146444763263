import instance from './axios'
const apis = {
    login : (data) => {
        return instance.post('/login' , data)
    },
    logout : () => {
        return instance.get('/logout')
    },
    checkAuth : () => {
        return instance.get('/checkAuth')
    },
    editProfile : (data) => {
        return instance.put('/editProfile' , data)
    },
    //Sliders
    fetchSliders : () => {
        return instance.get('/slider')
    },
    deleteSlider : (id) => {
        return instance.delete(`/slider/${id}`)
    },
    addSlider : (data) => {
        return instance.post(`/slider` , data)
    },
    fetchSlider : (id) => {
        return instance.get(`/slider/${id}`)
    },
    editSlider : (id , data) => {
        return instance.put(`/slider/${id}` , data)
    },
    //Services
    fetchServices : () => {
        return instance.get('/service')
    },
    deleteService : (id) => {
        return instance.delete(`/service/${id}`)
    },
    addService : (data) => {
        return instance.post(`/service` , data)
    },
    fetchService : (id) => {
        return instance.get(`/service/${id}`)
    },
    editService : (id , data) => {
        return instance.put(`/service/${id}` , data)
    },
    //Products
    fetchProducts : () => {
        return instance.get('/product')
    },
    deleteProduct : (id) => {
        return instance.delete(`/product/${id}`)
    },
    addProduct : (data) => {
        return instance.post(`/product` , data)
    },
    fetchProduct : (id) => {
        return instance.get(`/product/${id}`)
    },
    editProduct : (id , data) => {
        return instance.put(`/product/${id}` , data)
    },
    //Posts
    fetchPosts : () => {
        return instance.get('/post')
    },
    deletePost : (id) => {
        return instance.delete(`/post/${id}`)
    },
    addPost : (data) => {
        return instance.post(`/post` , data)
    },
    fetchPost : (id) => {
        return instance.get(`/post/${id}`)
    },
    editPost : (id , data) => {
        return instance.put(`/post/${id}` , data)
    },
    //About Us
    fetchAboutUs : () => {
        return instance.get('/about-us')
    },
    deleteAboutUs : (id) => {
        return instance.delete(`/about-us/${id}`)
    },
    addAboutUs : (data) => {
        return instance.post(`/about-us` , data)
    },
    fetchAboutUsOne : (id) => {
        return instance.get(`/about-us/${id}`)
    },
    editAboutUs : (id , data) => {
        return instance.put(`/about-us/${id}` , data)
    },
    //Team
    fetchTeam : () => {
        return instance.get('/team')
    },
    deleteTeam : (id) => {
        return instance.delete(`/team/${id}`)
    },
    addTeam : (data) => {
        return instance.post(`/team` , data)
    },
    fetchTeamOne : (id) => {
        return instance.get(`/team/${id}`)
    },
    editTeam : (id , data) => {
        return instance.put(`/team/${id}` , data)
    },
    //Testimonials
    fetchTestimonials : () => {
        return instance.get('/testimonial')
    },
    deleteTestimonial : (id) => {
        return instance.delete(`/testimonial/${id}`)
    },
    addTestimonial : (data) => {
        return instance.post(`/testimonial` , data)
    },
    fetchTestimonial : (id) => {
        return instance.get(`/testimonial/${id}`)
    },
    editTestimonial : (id , data) => {
        return instance.put(`/testimonial/${id}` , data)
    },
    //Info
    fetchInfo : () => {
        return instance.get('/info')
    },
    editInfo : (data) => {
        return instance.put(`/info` , data)
    }
}

export default apis