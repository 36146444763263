import React from 'react'
import EditServiceComponent from '../../components/dashboard/EditService'

const EditService = () => {
    return (
        <EditServiceComponent />
    )
}

export default EditService
