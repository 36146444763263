import React from 'react'
import EditTeamComponent from '../../components/dashboard/EditTeam'

const EditTeam = () => {
    return (
        <EditTeamComponent />
    )
}

export default EditTeam
