import React from 'react'
import { RouteWithSubRoutes } from './routes/router';
import routes from './routes/router'
import { Switch , BrowserRouter as Router } from 'react-router-dom';
import APIContext from './context/Context';
import { SnackbarProvider } from 'notistack';

function App() {

  return (
    <APIContext>
    <Router>
    <SnackbarProvider maxSnack={3}>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
      </SnackbarProvider>
    </Router>
    </APIContext>
  );
}

export default App;
